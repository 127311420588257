import gsap from 'gsap'
import stickybits from 'stickybits'
import Swiper from 'swiper'
import { Navigation, Pagination, FreeMode, Thumbs } from 'swiper/modules'

// menus
const handleFixedBody = () => {
	let scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
	const bodyTag = document.querySelector('body')
	if (bodyTag.classList.contains('menu-open')) {
		bodyTag.style.position = 'fixed'
		bodyTag.style.top = `-${scrollY}`
	} else {
		scrollY = bodyTag.style.top
		bodyTag.style.position = 'relative'
		bodyTag.style.top = 0
		window.scrollTo(0, parseInt(scrollY || '0') * -1)
	}
}

const menuControl = () => {
	const menuBtn = document.querySelector('.menu-btn')
	const gradientOverlay = document.querySelector('.menu-gradient')
	const closeBtn = document.querySelector('.close')

	menuBtn.addEventListener('click', () => {
		document.body.classList.add('menu-open')
		handleFixedBody()
	})
	gradientOverlay.addEventListener('click', () => {
		document.body.classList.remove('menu-open')
		handleFixedBody()
	})
	closeBtn.addEventListener('click', () => {
		document.body.classList.remove('menu-open')
		handleFixedBody()
	})
}
menuControl()

const cardSwipers = document.querySelectorAll('.cards-swiper')
cardSwipers.forEach((swiper) => {
	const s = new Swiper(swiper, {
		modules: [Navigation],
		direction: 'horizontal',
		slidesPerView: 'auto',
		spaceBetween: 16,
		navigation: {
			nextEl: '.next',
			prevEl: '.prev',
		},
	})
})

const testimonialSwipers = document.querySelectorAll('.testimonial-swiper')
testimonialSwipers.forEach((swiper) => {
	const s = new Swiper(swiper, {
		modules: [Navigation, Pagination],
		direction: 'horizontal',
		slidesPerView: 1,
		spaceBetween: 16,
		navigation: {
			nextEl: '.next',
			prevEl: '.prev',
		},
		pagination: {
			el: '.swiper-pagination',
		},
	})
})

// Wipe on animation
const wipeOnTags = document.querySelectorAll('[data-animation="wipe-on"]')
wipeOnTags.forEach((tag) => {
	const lines = tag.querySelectorAll('.container')
	const timeline = gsap.timeline()
	lines.forEach((line) => {
		timeline.to(line, {
			duration: 0.3,
			width: '100%',
			ease: 'power1.inOut',
			stagger: 0.01,
		})
	})
})

const ctaMarquee = () => {
	const marqueeContainers = document.querySelectorAll(
		'.cta-marquee-container',
	)
	if (!marqueeContainers) return
	marqueeContainers.forEach((marqueeContainer) => {
		const marquee = marqueeContainer.querySelector('.marquee')
		const item = marquee.querySelector('.marquee-item').cloneNode(true)
		marquee.appendChild(item)

		const animation = marquee.animate(
			[
				{ transform: `translate(0, 0)` },
				{ transform: `translate(-50%, 0)` },
			],
			{
				duration: 60000,
				iterations: Infinity,
			},
		)
	})
}
ctaMarquee()

const fellowsMarquee = () => {
	const marqueeContainers = document.querySelectorAll(
		'.fellows-marquee-container',
	)
	if (!marqueeContainers) return
	marqueeContainers.forEach((marqueeContainer) => {
		const marquee = marqueeContainer.querySelector('.marquee')
		const dir = marquee.getAttribute('data-direction')
		let operator = ''
		dir === 'left' ? (operator = '-') : (operator = '')
		const item = marquee.querySelector('.marquee-item').cloneNode(true)
		marquee.appendChild(item)

		const animation = marquee.animate(
			[
				{ transform: `translate(0, 0)` },
				{ transform: `translate(${operator}50%, 0)` },
			],
			{
				duration: 120000,
				iterations: Infinity,
			},
		)
	})
}
fellowsMarquee()

// nav links
const activeAnchors = () => {
	const anchorHeader = document.querySelector('.anchor-subheader')
	if (!anchorHeader) return
	stickybits('.anchor-subheader', { useStickyClasses: true })
	const navA = anchorHeader.querySelectorAll('a')
	const headers = document.querySelectorAll('h2')
	window.onscroll = () => {
		let current = ''
		headers.forEach((header) => {
			const headerTop = header.offsetTop
			if (scrollY >= headerTop) {
				current = header.getAttribute('id')
			}
		})
		navA.forEach((a) => {
			a.classList.remove('active')
			if (a.classList.contains(current)) {
				a.classList.add('active')
			}
		})
	}
}

activeAnchors()

const handleFellows = () => {
	const fellowsContainer = document.querySelector('.c12-fellows')
	if (!fellowsContainer) return
	const sidebar = document.querySelector('.fellows-sidebar')
	const inner = sidebar.querySelector('.inner')
	const gradientOverlay = document.querySelector('.sidebar-gradient')
	const closeBtn = sidebar.querySelector('.close')
	const fellows = fellowsContainer.querySelectorAll('.fellow')
	fellows.forEach((fellow) => {
		const bioLink = fellow.querySelector('.bio-link')
		const data = fellow.getAttribute('data-json')
		const json = JSON.parse(data)

		bioLink.addEventListener('click', (e) => {
			e.preventDefault()
			inner.innerHTML = render(json)
			document.body.classList.add('sidebar-open')
			console.log(json)
			// handleFixedBody()
		})
	})

	gradientOverlay.addEventListener('click', () => {
		document.body.classList.remove('sidebar-open')
		// handleFixedBody()
	})
	closeBtn.addEventListener('click', () => {
		document.body.classList.remove('sidebar-open')
		// handleFixedBody()
	})

	const render = (json) => {
		console.log(json.fields)
		return `
			<div class="fellow-sidebar-content">
				<div class="header">
				<div class="profile">
						${
							json.fields.image
								? `
							<div class="headshot">
								<img src="${json.fields.image.sizes.medium}" alt="">
							</div>
							`
								: ''
						}
						
						<div class="name">
							<h3>
							${json.fields.title ? json.fields.title + ' ' : ''}
							${json.post.post_title}</h3>
							<div class="fellowship">
								${
									json.fields.fellowship
										? `<p class="fellowship-body">${json.fields.fellowship}</p>`
										: ''
								}
								${
									json.fields.institution
										? `<p class="institution"><em>${
												json.fields.institution
										  }</em>${
												json.fields.country
													? ', ' + json.fields.country
													: ''
										  }</p>`
										: ''
								}
								${
									json.fields.research_interest
										? `<p class="research-interest"><strong>Research Interest: </strong>${json.fields.research_interest}</p>`
										: ''
								}
								${
									json.fields.personal_website
										? `<p class="personal-website">
										<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
										<g id="Icon_feather-globe" data-name="Icon feather-globe" transform="translate(-1.5 -1.5)">
										  <path id="Path_56859" data-name="Path 56859" d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
										  <path id="Path_56860" data-name="Path 56860" d="M3,18H33" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
										  <path id="Path_56861" data-name="Path 56861" d="M18,3a22.95,22.95,0,0,1,6,15,22.95,22.95,0,0,1-6,15,22.95,22.95,0,0,1-6-15A22.95,22.95,0,0,1,18,3Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
										</g>
									  </svg>
									  <a href="${json.fields.personal_website}" target="_blank" rel="noopener noreferrer">personal website</a></p>`
										: ''
								}
							</div>
						</div>
					</div>
				</div>
				<div class="content">

					${
						json.fields.bio
							? `<div class="bio">
						<h4>Bio</h4>
						${json.fields.bio}	
					</div>`
							: ''
					}
					

					${
						json.fields.testimonial
							? `
						<div class="testimonial">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35.355" height="20.549" viewBox="0 0 35.355 20.549">
                                <defs>
                                    <clipPath id="clip-path">
                                    <rect id="Rectangle_266" data-name="Rectangle 266" width="35.355" height="20.549" transform="translate(0 0)" fill="none"></rect>
                                    </clipPath>
                                </defs>
                                <g id="Group_182" data-name="Group 182" transform="translate(0 0)">
                                    <g id="Group_181" data-name="Group 181" clip-path="url(#clip-path)">
                                    <path id="Path_594" data-name="Path 594" d="M0,19.646a88.767,88.767,0,0,1,2.973-9.372Q5.045,4.634,7.218,0H16.2q-2.708,10.95-4.143,20.548H.574Zm19.154,0a88.807,88.807,0,0,1,2.974-9.372Q24.2,4.634,26.373,0h8.982q-2.708,10.95-4.142,20.548H19.728Z" fill="#0d0e0f"></path>
                                    </g>
                                </g>
                            </svg>
							${json.fields.testimonial}
						</div>
						`
							: ''
					}


					${
						json.fields.publications &&
						json.fields.publications.length > 0
							? `<div class="publications">
						<h4>Publications</h4>
						${json.fields.publications
							.map((publication) => {
								return `<div class="publication">
									<h5>
									${
										publication.fields.url
											? `<a href="${publication.fields.url}" target="_blank" rel="noopener noreferrer">`
											: ''
									}
										${publication.post.post_title}
									${publication.fields.url ? `</a>` : ''}
									</h5>
									</div>`
							})
							.join('')}
					</div>`
							: ''
					}
				</div>
			</div>
		`
	}
}
handleFellows()

// Back to top
const backToTop = () => {
	const btn = document.querySelector('.c12-back-to-top')
	btn.addEventListener('click', () => {
		window.scrollTo(0, 0)
	})
	window.addEventListener('scroll', () => {
		if (window.scrollY > 200) {
			btn.classList.add('visible')
		} else {
			btn.classList.remove('visible')
		}
	})
}
backToTop()

// Gallery Swiper
const gallerySwiper = new Swiper('.gallery-swiper', {
	loop: true,
	spaceBetween: 16,
	slidesPerView: 3,
	freeMode: true,
	watchSlidesProgress: true,
	modules: [FreeMode, Thumbs, Navigation],
})
const gallerySwiper2 = new Swiper('.gallery-swiper-2', {
	loop: true,
	spaceBetween: 16,
	modules: [FreeMode, Thumbs, Navigation],
	navigation: {
		nextEl: '.gallery-swiper-2 .next',
		prevEl: '.gallery-swiper-2 .prev',
	},
	thumbs: {
		swiper: gallerySwiper,
	},
})
